import { defineStyle, defineStyleConfig, extendTheme } from '@chakra-ui/react'
import { mode } from '@chakra-ui/theme-tools'

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts
const buttonOutline = defineStyle((props) => {
  // const { colorScheme: c, theme } = props
  return {
    fontWeight: 400,
    color: mode(`black`, `white`)(props),
    border: '2px solid',
    borderColor: mode(`black`, `white`)(props),
    borderRadius: 'full',
    bg: mode(`white`, `black`)(props),
    _hover: {
      textDecoration: 'none',
      borderColor: 'accent.200',
    },
    '_active, &.is-active': {
      borderColor: mode(`black`, `white`)(props),
      color: mode(`black`, `white`)(props),
      bg: 'accent.200'
    },
  }
})

const buttonSizes = {
  sm: defineStyle({
    h: '9',
    minW: '12',
    fontSize: 'sm',
    px: '3',
  }),
  md: defineStyle({
    h: '12',
    minW: '12',
    fontSize: 'md',
    px: '4',
  }),
  xl: defineStyle({
    h: '14',
    minW: '12',
    fontSize: 'lg',
    px: '6',
  }),
}

const buttonTheme = defineStyleConfig({
  baseStyle: {
    fontWeight: 400
  },
  sizes: { ...buttonSizes },
  variants: {
    ppkk: buttonOutline,
    // @TODO: customize color scheme of `ghost` variant
  },
})

const underlinedLink = defineStyle({
  color: 'dark',
  fontSize: 'sm',
  fontFamily: 'body',
  fontWeight: 400,
  textDecorationLine: 'underline',
  textUnderlineOffset: '0.125em',
  textDecorationColor: 'dark',
  textDecorationThickness: '0.075em',
  _hover: {
    textDecorationColor: 'transparent',
    _disabled: {
      textDecorationColor: 'dark',
    },
  },
  _active: {
    textDecorationColor: 'dark'
  }
})

const headerLink = defineStyle((props) => {
  return {
    fontSize: { base: 'md', md: 'lg' },
    textTransform: 'uppercase',
    color: mode(`black`, `white`)(props),
    textDecorationColor: 'transparent',
    textDecorationLine: 'underline',
    textUnderlineOffset: '0.125em',
    textDecorationThickness: '0.075em',
    _hover: {
      textDecorationColor: mode(`black`, `white`)(props),
      _disabled: {
        textDecorationColor: 'dark',
      },
    },
    '&.is-active:hover': {
      textDecorationColor: 'accent.200'
    },
    '_active, &.is-active': {
      color: 'accent.200'
    }
  }
})

const linkTheme = defineStyleConfig({
  variants: { ppkk: underlinedLink, header: headerLink },
})

const Text = {
  baseStyle: {
    fontFamily: 'body',
    fontWeight: 400
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      lineHeight: 'sm'
    },
    lg: {
      fontSize: { base: 'md', md: 'lg' },
      lineHeight: { base: 'md', md: 'lg' }
    }
  },
  defaultProps: {
    size: 'sm'
  }
}

const theme = {
	config: {
		initialColorMode: 'system',
	},
	styles: {
		global: (props) => ({
			'html, body': {
        color: props.colorMode === 'dark' ? 'white' : 'black',
        bg: props.colorMode === 'dark' ? 'black' : 'white',
			},
      '.linkBox': {
        ':hover, &.is-active': {
          '.chakra-button': {
            borderColor: 'accent.200'
          },
          '.media::before': {
            content: `''`,
            width: '100%',
            height: '100%',
            top: 0,
            zIndex: 'docked',
            position: 'absolute',
            bg: 'accent.overlay'
          }
        }
      },
      'p + p': {
        marginTop: 4,
      },
			'p:last-child':{
				marginBottom: '0 !important'
			}
		}),
	},
	fonts: {
		body: `'IBM Plex Mono', sans-serif`,
	},
	colors: {
		white: '#FFFFF0',
		black: '#190F0F',
    accent: {
      overlay: 'rgb(167, 212, 209 / 25%)',
      200: '#ff73f7'
    }
	},
  shadows: {
    outline: 'transparent'
  },
	fontSizes: {
		sm: '18px',
    md: '28.8px', // 30px (?)
    lg: '36px'
	},
  lineHeights: {
    sm: '21.6px',
    md: '34.56px', // 36px (?)
    lg: '43.2px'
  },
	components: {
    Text,
    Heading: Text,
    Link: linkTheme,
    Button: buttonTheme,
		Container: {
			sizes: {
				full: {
					maxW: 'full',
					paddingX: { base: 5, lg: 10 },
				}
			},
			defaultProps: {
				size: 'full'
			}
		}
	}
}

export default extendTheme(theme)
